import { computed } from 'vue'
import { getStorageAsset } from '@/utils/storage.js'

export function useLeague (league) {
  const icon = computed(() => {
    if (league.icon) {
      return getStorageAsset(league.icon)
    } else if (league.country.icon) {
      return getStorageAsset(league.country.icon)
    } else {
      return 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs='
    }

  })

  const color = league.color

  return {
    icon,
    color
  }
}
