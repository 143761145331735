<template>
  <QDialog
    v-model="authStore.showMessageModel"
    persistent
  >
    <div class="relative px-10 w-full md:w-sm lg:w-md dark:bg-dark-1 light:bg-light-1">
      <div class="py-8 flex flex-col items-center justify-center gap-7">
        <div>
          쪽지가 도착했습니다
        </div>
        <QSeparator
          :color="configStore.darkMode ? 'dark-3' : 'light-3'"
          class="w-full"
        />
        <QBtn
          v-close-popup
          dense
          unelevated
          label="쪽지확인"
          class="w-55 h-10 text-white"
          color="primary"
          :to="{ name: 'messages'}"
        />
      </div>
    </div>
  </QDialog>
</template>

<script setup>
import {useAuthStore} from '@/stores/auth'
import {useConfigStore} from "@/stores/config";

const authStore = useAuthStore()
const configStore = useConfigStore()
</script>
