import { isSameSportItem, isSameSportMarket, isSameMiniGameItem, isSameVirtualGameItem, isSameDuotoneBallItem } from '@/utils/cart.js'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useCartStore = defineStore('cart', () => {
  const items = ref([])
  const amount = ref(0)

  function add (item) {
    items.value.push(item)
  }

  function remove (index) {
    items.value.splice(index, 1)
  }

  function clear () {
    items.value = []
  }

  function update (index, item) {
    items.value[index] = item
  }

  function selectSportItem (item) {
    const index = items.value.findIndex(o => isSameSportMarket(o, item))
    if (index > -1) {
      const sameItem = items.value.find(o => isSameSportItem(o, item))
      remove(index)
      if (!sameItem) {
        add(item)
      }
    } else {
      add(item)
    }
  }

  function selectMiniGameItem (item) {
    const index = items.value.findIndex(o => isSameMiniGameItem(o, item))
    clear()
    if (index <= -1) {
      add(item)
    }
  }

  function selectVirtualGameItem (item) {
    const index = items.value.findIndex(o => isSameVirtualGameItem(o, item))
    clear()
    if (index <= -1) {
      add(item)
    }
  }


  function selectDuotoneBallItem (item) {
    const index = items.value.findIndex(o => isSameDuotoneBallItem(o, item))
    if (index <= -1 && items.value.length <= 5) {
      add(item)
    }
    if(index > -1){
      remove(index)
    }
  }

  return {
    items,
    amount,
    add,
    remove,
    clear,
    update,
    selectSportItem,
    selectMiniGameItem,
    selectVirtualGameItem,
    selectDuotoneBallItem,
  }
})
