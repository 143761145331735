import { useSportStore } from '@/stores/common/sport.js'
import { api } from '@/plugins/axios'
import { echo } from '@/plugins/echo'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { groupBy } from 'lodash-es'

export const useLiveStore = defineStore('live', () => {
  const sportStore = useSportStore()

  const games = ref([])
  const hotGames = ref([])
  const sportCount = ref([])

  const fetch = async () => {
    sportStore.fetching = true
    const { data } = await api.get('lives')
    sportStore.games = new Map(data.data.map(game => [game.live_id, game]))
    sportStore.fetching = false
  }

  const fetchHot = async () => {
    const { data } = await api.get('lives/hot')
    hotGames.value = data.data

    return data
  }

  const fetchCount = async () => {
    const { data } = await api.get('lives')
    games.value = data.data

    sportCount.value = groupBy(data.data, game => game.sport_id)
  }

  const subscribe = () => {
    echo.channel('live-event')
      .listen('.updated', (game) => {
        sportStore.games.set(game.live_id, game)
      })
      .listen('.removed', ({ id }) => {
        sportStore.games.delete(id)
      })
  }

  async function unsubscribe () {
    echo.leaveChannel('live-event')
  }

  return {
    hotGames,
    fetch,
    fetchHot,
    fetchCount,
    games,
    sportCount,
    subscribe,
    unsubscribe,
  }
})
