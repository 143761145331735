import Outlined from '@/icons/outlined.svg?raw'
import Filled from '@/icons/filled.svg?raw'
import Colorful from '@/icons/colorful.svg?raw'

const iconsMap = {
  outlined:Outlined,
  filled:Filled,
  colorful:Colorful,
}
export default {
  install: (app, options = { style: 'outlined' }) => {
    const container = document.createElement('div')
    container.innerHTML = iconsMap[options.style]
    document.body.appendChild(container)
  }
}

