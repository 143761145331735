<template>
  <QDialog
    v-model="authStore.showGuestQuestionModal"
    persistent
  >
    <div class="relative px-10 w-full md:w-sm lg:w-md dark:bg-dark-1 light:bg-light-1">
      <div class="py-8 flex justify-center">
        <Logo
          class="w-1/2"
          :dark="$q.dark.isActive"
        />
      </div>
      <QForm
        @submit.prevent="submit"
      >
        <div class="flex flex-col gap-4">
          <QInput
            v-model="form.name"
            placeholder="회원ID, 텔레그램ID 입력하세요"
            outlined
            dense
            :bg-color="$q.dark.isActive ? 'dark-3':'light-3'"
          />
          <QInput
            v-model="form.mobile"
            placeholder="휴대폰번호를 입력하세요"
            outlined
            dense
            :bg-color="$q.dark.isActive ? 'dark-3':'light-3'"
          />
          <QInput
            v-model="form.content"
            placeholder="문의 내용을 입력하세요"
            outlined
            dense
            type="textarea"
            :bg-color="$q.dark.isActive ? 'dark-3':'light-3'"
          />
        </div>
        <div class="my-8 grid grid-cols-2 gap-4">
          <QBtn
            label="보내기"
            color="primary"
            unelevated
            :loading="questionStore.creating"
            type="submit"
          />
          <QBtn
            v-close-popup
            label="취소"
            color="primary"
            unelevated
            class="text-white"
          />
        </div>
      </QForm>
    </div>
  </QDialog>
</template>

<script setup>
import Logo from '@/components/Logo.vue'
import { useAuthStore } from '@/stores/auth.js'
import { useQuestionStore } from '@/stores/question.js'
import { reactive } from 'vue'

const authStore = useAuthStore()
const questionStore = useQuestionStore()

const form = reactive({
  name: '',
  mobile: '',
  content: ''
})

async function submit(){
  await questionStore.guest({
    title: `${form.name}|${form.mobile}`,
    content: form.content,
  })
  authStore.showGuestQuestionModal = false
}
</script>
